






































































































































































































.el-tag {
  margin-right: 10px;
  margin-bottom: 10px;
}

// .caseVue {
//   .header {
//     height: 20rem !important;
//     // background: linear-gradient(90deg, #2d5fcd 0, #7b5ab6 100%), #2839a0;
//     // background-image:url(imagePath)
//   }

//   main {
//     margin-top: 2rem;
//   }
// }

.html.quarterly {
  img {
    margin: 2rem 0;
    width: 100%;
    text-align: center;
  }

  p {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.html {
  img {
    max-width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .html {
    img {
      width: 100%;
    }
  }
}
